const util = Ingtech.util;


class Notification extends EventEmitter {
	constructor() {
		super();

		this.attachBroker();

		this.sync();

		this._redirections = {};

		return this;
	}

	attachBroker() {
		this.broker = Ingtech.Broker.scope('notification');

		this.broker.on('new', message => {
			this.onNew(message.body.notification);
		});
	}


	async ackBySource(source, sourceReference) {
		let matchingNotifications = this.notifications.filter(notification =>
			notification.source == source && notification.sourceReference == sourceReference);

		if (matchingNotifications.length > 0) {
			this.broker.emit('ack-by-source', { source, sourceReference });

			for (let { id: notificationId } of matchingNotifications) {
				let index = this.notifications.indexOf(this.notifications.find(notification => notification.id == notificationId));

				if (index >= 0) this.notifications.splice(index, 1);
			}

			this.updateMenu();
		}
	}

	async sync() {
		if (Ingtech.requestedPage == 'login') return;

		if (!Ingtech.cookie.token) return;

		try {
			let notifications = await $.get('/json/notifications', { "_": $.now() });
			this.notifications = notifications;
	
			this.updateMenu();
		} catch (err) {
			console.error(err);
		}
	}

	get summary() {
		return this.notifications.reduce((summary, notification) => {
			summary[notification.source] = summary[notification.source] || {
				count: 0,
				priority: 0
			};
			let info = summary[notification.source];

			info.count++;
			info.priority = Math.max(notification.priority || 0, info.priority);

			return summary;
		}, {});
	}

	async onNew(notification) {
		this.notifications.push(notification);

		if (await this.emit('new', notification) === true) return;
		if (await this.emit(`new-${notification.source}`, notification) === true) return;

		this.updateMenu();
		this.notify(notification);
	}

	updateMenu() {
		$('.notification-dot').removeClass('new redflag');

		for (let [source, summary] of Object.entries(this.summary)) {
			let $dot = $(`.notification-dot[data-source="${source}"]`);

			$dot.toggleClass('new', summary.count > 0);
			$dot.toggleClass('redflag', summary.priority > 0);
		}
	}

	notify(notification) {
		let notificationType = notification.additionalInformations && notification.additionalInformations.notificationType || 'info';

		toastr[notificationType](util.i18nFormat(notification.description, true), util.i18nFormat(notification.title, true), {
			onclick: async () => {
				if (await this.emit('click', notification) === true) return;
				if (await this.emit(`click-${notification.source}`, notification) === true) return;

				if (this._redirections[notification.source]) {
					this._redirections[notification.source](notification);
				}
			}
		});


		// window.Notification.requestPermission().then(() => {
		// 	new window.Notification(util.i18nFormat(notification.title, true), {
		// 		icon: "/css/images/favicon.ico",
		// 		body: util.i18nFormat(notification.description, true)
		// 	}).onclick = async () => {
		// 		if (await this.emit('click') === true) return;
		// 		if (await this.emit(`click-${notification.source}`) === true) return;

		// 		if (this._redirections[notification.source]) {
		// 			this._redirections[notification.source](notification);
		// 		}
		// 	};
		// });
	}

	setRedirection(source, callback) {
		this._redirections[source] = callback;
	}
}

let NotificationSingleton = new Notification();

NotificationSingleton.TYPE = {
  UNDEFINE: 0,
  MESSAGING: 1,
  HOS_SETTINGS: 2
};

NotificationSingleton.STATUS = {
  UNSEEN: 0,
  SEEN: 1
};

Ingtech.Notification = NotificationSingleton;